import { FC, useEffect, useRef } from 'react'
import { SectionFourSixtyInstagramFeedProps } from '@magal/models'
import { useInView } from 'react-intersection-observer'
import { styled } from '@magal/styles'
import { SectionWrap, BlockContent } from '@magal/components'

const Root = styled('div', {
  maxWidth: '$maxWidthL',
  margin: '0 auto',
  display: 'grid',
  gridAutoFlow: 'row',
  gridGap: '$64',
  color: '$green_09',
})

const TitleAndDescription = styled('div', {
  maxWidth: '$maxWidthS',
  margin: '0 auto',
  display: 'grid',
  gridAutoFlow: 'row',
  gridGap: '$20',
  padding: '0 $24',
  textAlign: 'center',
})

const Title = styled('h3', {
  projectFont: 'caps03',
})

const PlaceholderTiles = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'repeat(3, auto)',
  gridGap: '1px',
  /**
   * Aligns with Four Sixty breakpoint for grid changes
   */
  '@media (min-width: 768px)': {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'repeat(2, auto)',
  },
})

const PlaceholderTile = styled('div', {
  paddingBottom: `${(1 / 1) * 100}%`,
  background: '$gray300',
})

const FeedContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1/1',
  gridTemplateRows: '1/1',

  '& > div': {
    gridColumn: 1,
    gridRow: 1,
  },
})

const TILES_AMOUNT = 6

export const SectionFourSixtyInstagramFeed: FC<
  SectionFourSixtyInstagramFeedProps
> = ({ title, description }) => {
  const { ref: containerRef, inView } = useInView({
    rootMargin: '400px',
    triggerOnce: true,
  })
  const feedRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (inView) {
      /**
       * Four Sixty script has to be by interacting with DOM api instead of using
       * next/script since <script> tag has to be added in a particular place in
       * in a DOM tree. Widget will be rendered based on <script> position
       */
      const s = document.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://foursixty.com/media/scripts/fs.embed.v2.5.js'
      s.setAttribute('data-feed-id', 'magal')
      s.setAttribute('data-theme', 'showcase_v2_5')
      s.setAttribute('data-open-links-in-same-page', 'true')
      s.setAttribute('data-page-size', `${TILES_AMOUNT}`)

      if (feedRef && feedRef.current) {
        feedRef.current.appendChild(s)
      }
    }
  }, [inView])

  return (
    <SectionWrap ref={containerRef}>
      <Root>
        {(title || description) && (
          <TitleAndDescription>
            {title && <Title>{title}</Title>}
            {description && <BlockContent body={description} />}
          </TitleAndDescription>
        )}

        <FeedContainer ref={feedRef} className="fs-section">
          <PlaceholderTiles>
            {Array(6)
              .fill(0)
              .map((_, i) => (
                <PlaceholderTile key={i} />
              ))}
          </PlaceholderTiles>
          {inView && (
            <style>
              {`.fs-has-links { display:none; } .fs-showcase_v2_5.fs-desktop .fs-entry-container { width:33.333333333333336% !important;padding-top:33.333333333333336% !important; } .fs-showcase_v2_5.fs-mobile .fs-entry-container { width:50% !important;padding-top:50% !important; } .fs-wrapper div.fs-text-container .fs-entry-title, div.fs-detail-title { font-family:FormaDJRMicro, Helvetica, sans-serif;font-style:normal;font-weight:normal;font-size:16px;color:#565C54 } div.fs-text-container .fs-entry-date, div.fs-detail-container .fs-post-info, div.fs-wrapper div.fs-has-links::after, .fs-text-product, .fs-overlink-text { font-family:termina, Helvetica, sans-serif, sans-serif;font-style:normal;font-weight:normal;font-size:14px; } .fs-wrapper div.fs-text-container * { color:#ffffff; } .fs-wrapper div.fs-text-container { background-color:rgba(86,92,84,0.8);margin:0px; } div.fs-entry-date { display:none; } div.fs-entry-title { display:none; } .fs-showcase_v2_5.fs-wrapper div.fs-timeline-entry { margin:1px; } div.fs-timeline-detail {background-color:rgba(255, 252, 249, 0.74)`}
            </style>
          )}
        </FeedContainer>
      </Root>
    </SectionWrap>
  )
}
